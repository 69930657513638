.leftnav {
  position: absolute;
  /* top: 0% ; */
  /* left: 1%; */
  top: 70px;
  bottom: 0;
  /* height: calc(100vh - 70px); */
  height: 100%;
  z-index: 9999;
  width: 232px;
  background-image: linear-gradient(to bottom left, #2d60f6, #4cb5ff);
  display: block !important;

  /* box-shadow: 0 0 40px 0 rgba(0,0,0,0.9); */
}
.leftnav ul li {
  line-height: 45px;
  list-style: none;
  margin-left: -13px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
}
.leftnav ul li:hover {
  color: #fff;
  /* border-left: none;
    margin-left: -20px;
    border-radius: 30px;
  
    animation: animate 5s linear infinite; */
}

@keyframes animate {
  0% {
    box-shadow: inset 0 0 50px 0 rgb(147, 190, 230);
  }
  25% {
    box-shadow: inset 0 0 30px 0 rgb(67, 161, 249);
  }
  50% {
    box-shadow: inset 0 0 10px 0 rgb(67, 161, 249);
  }
  75% {
    box-shadow: inset 0 0 30px 0 rgb(67, 161, 249);
  }
  100% {
    box-shadow: inset 0 0 50px 0 rgb(122, 169, 213);
  }
}
.leftnav ul li .title {
  margin-left: 9px;

  /* color: #fff; */
}
.leftnav ul li ion-icon {
  /* color: #fff; */

  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.9);
}
/* .leftnav ul li:hover ion-icon{
    padding-left: 5px;
    font-size: 26px;
    
} */
h2,
h1 {
  display: none;
  font-size: 1px;
  color: #fff;
}

@media only screen and (max-width: 980px) {
  .leftnav {
    position: absolute;
    background-image: linear-gradient(
      to bottom right,
      rgba(46, 144, 243, 0.861),
      rgb(1, 94, 208)
    );
    top: 70px;
    left: 0px;
    width: 380px;
    height: 100%;
    bottom: 100px;
    z-index: 1000;
  }

  h2,
  h1 {
    font-size: 24px;
    display: block;
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 980px) {
  /* Your CSS styles for screens between 757px and 900px */
  .leftnav {
    height: 150%;
  }
}
@media only screen and (max-width: 500px) {
  .leftnav {
    width: 100%;
  }
}



.none {
  display: none;
}
.block {
  display: block;
}
