.tablebox{
height: 400px;
 width: 1000px; 
padding: 30px;
border-radius: 40px;
background-color: rgb(245, 240, 240);
}
 .table{
    
    background-color: transparent;
    height: 400px;
    /* width: 82%;  */
    margin-left: 12px;
    z-index: 1 !important;
    overflow: hidden;
} 
@media only screen and (max-width: 980px){
    .table{
        background-color: transparent;
        width: 100% !important;
        margin-left: 12px;
        z-index: 1 !important;
        overflow: hidden;
    }   
}

.eventdetailTable{
    overflow: auto;
} 
