.main {
  position: relative;
  /* height: 82vh; */
  height: 90vh;
  /* width: calc(100%-280px) !important; */
  width: calc(100% - 232px);
  left: 232px;
  top: 1px;
  overflow-x: auto;
  border-radius: 10px;
  /* margin: 0 auto; */
  min-width: 300px;
  margin-bottom: 2rem;
  /* max-width: 1200px; */
}

.dashboardMain {
  overflow-x: inherit !important;
}

.gridbox {
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right bottom,
      rgba(68, 130, 255, 0.932),
      rgb(131, 152, 240));
  /* margin-top: 40px; */

  height: 50px;
  width: 94%;
  margin-left: 3%;

  border-radius: 8px;
}

.gridbox .divider {
  margin-top: 14px;
}

/* .table {
  z-index: 1 !important;
  overflow: scroll;
} */

@media only screen and (max-width: 767px) {
  .gridbox {
    width: 90% !important;
  }

  .table {
    width: 90% !important;
  }

  .gridbox button {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 5px 10px; */
  }

  .gridbox button span {
    /* padding: 5px 10px; */
  }

  .toggle {
    width: 100px !important;
  }

  /* .slick-list {
    width: 123% !important;
  } */
  /* .nextArrow {
    right: -61px;
  } */
}

@media only screen and (min-width: 1440px) {
  .main {
    height: 88vh;
  }
}

.gridbox span {
  /* height: 50px; */
  font-weight: 700;
  /* margin-top: 10px; */
  color: rgb(255, 255, 255);
}

.topbuttons {
  height: 50px;
  background: transparent;
  width: 100%;
  border: none;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}

.afterclicktopbuttons {
  height: 50px;
  background-image: linear-gradient(to bottom right,
      rgb(9, 65, 250),
      rgba(2, 67, 245, 0.747));
  width: 100%;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}

.responsivebox {
  display: flex;

  justify-content: space-between;

  width: 82%;
  padding: 3px;
  padding: 10px;
  /* background: red; */
}

.focus {
  height: 100px;
  width: 231px;
  background: rgb(255, 255, 255);
  aspect-ratio: 16/9;
  border-radius: 15px;
  font-size: 50px;
  padding: 10px;
  color: rgba(0, 41, 247, 0.799);
  border: 0.4px solid #a4a4ff;
  text-shadow: -1px 2px transparent;
  font-weight: 900;
  box-shadow: 0 0 40px 0 rgba(203, 202, 202, 0.9);
  animation: focus 2s linear infinite;
  transition: 0.5s;
}

.focus:hover {
  box-shadow: 0 0 160px 0 rgba(223, 219, 219, 0.9);
}

.focus::before {
  content: "";
  padding: 10px;
  position: absolute;
  height: 90px;

  width: 220px;
  filter: blur(10px);
  /* opacity: 0.6; */
}

.focus:nth-child(1)::after {
  content: "ALL";
  position: absolute;
  right: 20px;
  top: 28px;
  font-size: 24px;
  color: rgba(83, 93, 231, 0.68);
  z-index: 10000;
}

.focus:nth-child(2)::after {
  content: "APPROVED";
  position: absolute;
  right: 20px;
  top: 28px;
  font-size: 24px;
  color: rgba(83, 93, 231, 0.68);
  z-index: 1;
}

.focus:nth-child(3)::after {
  content: "UNAPPROVED";
  position: absolute;
  right: 20px;
  top: 28px;
  font-size: 24px;
  color: rgba(83, 93, 231, 0.68);
  z-index: 1;
}

.focus:nth-child(4)::after {
  content: "DRAFT";
  position: absolute;
  right: 20px;
  top: 28px;
  font-size: 24px;
  color: rgba(83, 93, 231, 0.68);
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .responsivebox {
    display: block;
    width: 82%;
  }

  .focus {
    margin-top: 10px;
    width: 355px;
  }

  .focus::before {
    width: 335px;
  }
}

@keyframes focus {
  0% {
    color: rgba(0, 41, 247, 0.799);
    font-weight: 800;
    /* transform: rotateX(0deg); */
    transform: scale(1);
    text-shadow: -1px 2px transparent;
  }

  100% {
    font-weight: 900;
    /* transform: scale(1); */
    transform: scale(3);
    transform: rotateY(4deg);
    text-shadow: -1px 19px transparent;
  }
}

.detailbox {
  position: absolute;
  left: 20%;
  top: 2%;
  height: auto;
  padding: 5px;
  width: 300px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
  z-index: 999;
  border-radius: 8px;
}

.padding-left {
  position: absolute;
}

.detailbox span {
  display: block;
  padding: 8px;
  padding-left: 30px;
}

.detailbox input {
  width: 100px !important;
}

.cross {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  border: solid red 2px;
  height: 20px;
  background-color: red;
  border-radius: 4px;
  font-weight: bold;
}

.alertposition {
  position: absolute;
  top: -10%;
  z-index: 10001;
  left: -13%;
  width: 100%;
}