

.eventdetails{
  width: 100%;
}
.ticketbox{
  position: absolute;
  top: calc(50% - 125px);
  top: -webkit-calc(50% - 120px);
  left: calc(50% - 300px);
  left: -webkit-calc(50% - 396px);
  }
  @media only screen and (max-width: 400px){
    .ticketbox{
      left: 0 !important;
      right: 0;
      width: 100% !important;
    }
    .eventdetails div {
      width: 90% !important;
      line-height: 1;
  }
  }

  @media only screen and (max-width: 767px){
.ticketbox{
  left: -webkit-calc(50% - 200px);
  width: 395px;
  overflow:hidden;
  top: 17% !important;
}
.boarding{
 position: absolute;
 right: 0 !important;
}
.face{
  position: absolute;
  width: 65px !important;
  height: 65px !important;
  
  border-radius: 50%;
  border: 1px solid #777;
  top: 10% !important;
  left: 36.5% !important;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}
.shadow{
  position: absolute;
    width: 32% !important;
    height: 3%;
    opacity: 0.5;
    background: #777;
    left: 34% !important;
    top: 15% !important;
    border-radius: 50%;
    z-index: 1;
}
  }

  .ticket{
    width: 792px;
    height: 250px;
    background: rgb(55, 108, 255);
    border-radius: 3px;
    box-shadow: 0 0 100px #aaa;
    border-top: 1px solid #E89F3D;
    border-bottom: 1px solid #E89F3D;
  }

   
  @media only screen and (max-width: 970px){
    .ticket{
     transform: scale(0.8) !important;
     
    }
  }
  
  
  @media only screen and (max-width: 767px){
    .ticket{
      width: 792px;
      height: 761px;
      background: rgb(55, 108, 255);
      border-radius: 3px;
      box-shadow: 0 0 100px #aaa;
      border-top: 1px solid #E89F3D;
      border-bottom: 1px solid #E89F3D;
      transform: none !important;
    }
    .airline{
      position: relative;
      
    }
    .ticketbox {
     
      top: -webkit-calc(50% - 265px);
     
  }
  .content{height: 608px !important;}
  .verfifyTckt p{
  /* display: block; */
    justify-content: space-between;
  }
  .eventdetails {
    display: block;
  }
  .eventdetails div{
    width: 100% !important;
    line-height: 1;
  }
  .marginFoeMobile{
    margin-top: -55px;
  }
  }
  
  .left{
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0px;
    left: -5px;
  }
  
  .left li{
    width: 0px;
    height: 0px;
  }
  
  .left li:nth-child(-n+2){
    margin-top: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid rgb(55, 108, 255);
  }
  
  .left li:nth-child(3),
  .left li:nth-child(6){
    margin-top: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #EEEEEE;
  }
  
  .left li:nth-child(4){
    margin-top: 8px;
    margin-left: 2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #EEEEEE;
  }
  
  .left li:nth-child(5){
    margin-top: 8px;
    margin-left: -1px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent; 
    border-right: 6px solid #EEEEEE;
  }
  
  .left li:nth-child(7),
  .left li:nth-child(9),
  .left li:nth-child(11),
  .left li:nth-child(12){
    margin-top: 7px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #E5E5E5;
  }
  
  .left li:nth-child(8){
    margin-top: 7px;
    margin-left: 2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #E5E5E5;
  }
  
  .left li:nth-child(10){
    margin-top: 7px;
    margin-left: 1px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #E5E5E5;
  }
  
  .left li:nth-child(13){
    margin-top: 7px;
    margin-left: 2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid rgb(55, 108, 255);
  }
  
  .left li:nth-child(14){
    margin-top: 7px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid rgb(55, 108, 255);
  }
  
  .right{
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0px;
    right: -5px;
  }
  
  .right li:nth-child(-n+2){
    margin-top: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid rgb(55, 108, 255);
  }
  
  .right li:nth-child(3),
  .right li:nth-child(4),
  .right li:nth-child(6){
    margin-top: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #EEEEEE;
  }
  
  .right li:nth-child(5){
    margin-top: 8px;
    margin-left: -2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #EEEEEE;
  }
  
  .right li:nth-child(8),
  .right li:nth-child(9),
  .right li:nth-child(11){
    margin-top: 7px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #E5E5E5;
  }
  
  .right li:nth-child(7){
    margin-top: 7px;
    margin-left: -3px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #E5E5E5;
  }
  
  .right li:nth-child(10){
    margin-top: 7px;
    margin-left: -2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #E5E5E5;
  }
  
  .right li:nth-child(12){
    margin-top: 7px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent; 
    border-left: 6px solid #E5E5E5;
  }
  
  .right li:nth-child(13),
  .right li:nth-child(14){
    margin-top: 7px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-left: 5px solid rgb(55, 108, 255);
  }
  
  
  .content{
    position: absolute;
    top: 40px;
    width: 100%;
    height: 369px;
    background: #eee;
  }
  
  .airline{
    position: absolute;
    top: 10px;
    left: 10px;
    /* font-family: Arial; */
    font-size: 20px;
    font-weight: bold;
    color: rgba(0,0,102,1);
  }
  
  .boarding{
    position: absolute;
    top: 10px;
    right: 220px;
    /* font-family: Arial; */

    font-size: 18px;
    color: rgba(255,255,255,0.6);
  }
  
  .jfk{
    position: absolute;
    top: 10px;
    left: 20px;
    /* font-family: Arial; */
    font-size: 26px;
    color: #222;
  }
  
  .sfo{
    position: absolute;
    top: 10px;
    left: 180px;
    /* font-family: Arial; */
    font-size: 38px;
    color: #222;
  }
  
  .plane{
    position: absolute;
    left: 105px;
    top: 0px;
  }
  
  .sub-content{
    background: #e5e5e5;
    width: 100%;
    height: 100px;
    position: absolute;
    top: 70px;
  }
  
  .watermark{
    position: absolute;
    left: 100px;
    top: 10px;
    /* font-family: Arial; */
    font-size: 110px;
    font-weight: bold;
    color: rgba(255,255,255,0.2);
  }
  
  .name{
    position: absolute;
    top: 10px;
    left: 10px;
    /* font-family: Arial Narrow, Arial; */
    font-weight: bold;
    font-size: 14px;
    color: #999;
  }
  
  .name span{
    color: #555;
    font-size: 17px;
  }
  
  .flight{
    position: absolute;
    top: 10px;
    left: 180px;
    /* font-family: Arial Narrow, Arial; */
    font-weight: bold;
    font-size: 14px;
    color: #999;
  }
  
  .flight span{
    color: #555;
    font-size: 17px;
  }
  
  .gate{
    position: absolute;
    top: 10px;
    left: 300px;
    /* font-family: Arial Narrow, Arial; */
    font-weight: bold;
    font-size: 14px;
    color: #999;
  }
  
  .gate span{
    color: #555;
    font-size: 17px;
  }
  
  
  .seat{
    position: absolute;
    top: 10px;
    left: 350px;
    font-family: Arial Narrow, Arial;
    font-weight: bold;
    font-size: 14px;
    color: #999;
  }
  
  .seat span{
    color: #555;
    font-size: 17px;
  }
  
  .boardingtime{
    position: absolute;
    top: 60px;
    left: 10px;
    /* font-family: Arial Narrow, Arial; */
    font-weight: bold;
    font-size: 14px;
    color: #999;
  }
  
  .boardingtime span{
    color: #555;
    font-size: 17px;
  }
  
  .barcode{
    position: absolute;
    left: 8px;
    bottom: 6px;
    height: 30px;
    width: 90px;
    background: #222;
    box-shadow: inset 0 1px 0 rgb(55, 108, 255), inset -2px 0 0 rgb(55, 108, 255),
      inset -4px 0 0 #222,
      inset -5px 0 0 rgb(55, 108, 255),
      inset -6px 0 0 #222,
      inset -9px 0 0 rgb(55, 108, 255),
      inset -12px 0 0 #222,
      inset -13px 0 0 rgb(55, 108, 255),
      inset -14px 0 0 #222,
      inset -15px 0 0 rgb(55, 108, 255),
      inset -16px 0 0 #222,
      inset -17px 0 0 rgb(55, 108, 255),
      inset -19px 0 0 #222,
      inset -20px 0 0 rgb(55, 108, 255),
      inset -23px 0 0 #222,
      inset -25px 0 0 rgb(55, 108, 255),
      inset -26px 0 0 #222,
      inset -26px 0 0 rgb(55, 108, 255),
      inset -27px 0 0 #222,
      inset -30px 0 0 rgb(55, 108, 255),
      inset -31px 0 0 #222,
      inset -33px 0 0 rgb(55, 108, 255),
      inset -35px 0 0 #222,
      inset -37px 0 0 rgb(55, 108, 255),
      inset -40px 0 0 #222,
      inset -43px 0 0 rgb(55, 108, 255),
      inset -44px 0 0 #222,
      inset -45px 0 0 rgb(55, 108, 255),
      inset -46px 0 0 #222,
      inset -48px 0 0 rgb(55, 108, 255),
      inset -49px 0 0 #222,
      inset -50px 0 0 rgb(55, 108, 255),
      inset -52px 0 0 #222,
      inset -54px 0 0 rgb(55, 108, 255),
      inset -55px 0 0 #222,
      inset -57px 0 0 rgb(55, 108, 255),
      inset -59px 0 0 #222,
      inset -61px 0 0 rgb(55, 108, 255),
      inset -64px 0 0 #222,
      inset -66px 0 0 rgb(55, 108, 255),
      inset -67px 0 0 #222,
      inset -68px 0 0 rgb(55, 108, 255),
      inset -69px 0 0 #222,
      inset -71px 0 0 rgb(55, 108, 255),
      inset -72px 0 0 #222,
      inset -73px 0 0 rgb(55, 108, 255),
      inset -75px 0 0 #222,
      inset -77px 0 0 rgb(55, 108, 255),
      inset -80px 0 0 #222,
      inset -82px 0 0 rgb(55, 108, 255),
      inset -83px 0 0 #222,
      inset -84px 0 0 rgb(55, 108, 255),
      inset -86px 0 0 #222,
      inset -88px 0 0 rgb(55, 108, 255),
      inset -89px 0 0 #222,
      inset -90px 0 0 rgb(55, 108, 255);
  }
  
  .slip{
    left: 455px;
  }
  
  .nameslip{
    top: 60px;
    left: 410px;
  }
  
  .flightslip{
    left: 410px;
  }
  
  .seatslip{
    left: 540px;
  }
  
  .jfkslip{
    font-size: 30px;
    top: 20px;
    left: 410px;
  }
  
  .sfoslip{
    font-size: 30px;
    top: 20px;
    left: 530px;
  }
  
  .planeslip{
    top: 10px;
    left: 475px;
  }
  
  .airlineslip{
    left: 455px;
  }























 
 /* #container {
	 position: relative;
	 margin: auto;
	 overflow: hidden;
	 width: 700px;
	 height: 250px;
} */
 /* h1 {
	 font-size: 0.9em;
	 font-weight: 100;
	 letter-spacing: 3px;
	 padding-top: 5px;
	 color: #fcfcfc;
	 padding-bottom: 5px;
	 text-transform: uppercase;
} */
 .green {
	 color: #4ec07d;
}
 .red {
	 color: #e96075;
}
 /* .alert {
	 font-weight: 700;
	 letter-spacing: 5px;
} */

 #success-box {
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 /* left: 12%; */
	 background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);

	 box-shadow: 5px 5px 20px rgba(203, 205, 211, 10);
	 perspective: 40px;
}
 #error-box {
	 position: absolute;
   width: 100%;
	 height: 100%;
	 /* right: 12%; */
	 background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
	 
	 box-shadow: 5px 5px 20px rgba(203, 205, 211, 10);
}
 .dot {
	 width: 8px;
	 height: 8px;
	 background: #fcfcfc;
	 border-radius: 50%;
	 position: absolute;
	 top: 4%;
	 right: 6%;
}
 .dot:hover {
	 background: #c9c9c9;
}
 .two {
	 right: 12%;
	 opacity: 0.5;
}
 .face {
	 position: absolute;
	 width: 100px;
	 height: 100px;
	 background: #fcfcfc;
	 border-radius: 50%;
	 border: 1px solid #777;
	 top: 1%;
	 left: 45.5%;
	 z-index: 2;
	 animation: bounce 1s ease-in infinite;
}
 .face2 {
	 position: absolute;
	 width: 22%;
	 height: 22%;
	 background: #fcfcfc;
	 border-radius: 50%;
	 border: 1px solid #777;
	 top: 21%;
	 left: 37.5%;
	 z-index: 2;
	 animation: roll 3s ease-in-out infinite;
}
 .eye {
	 position: absolute;
	 width: 5px;
	 height: 5px;
	 background: #777;
	 border-radius: 50%;
	 top: 40%;
	 left: 20%;
}
 .right {
	 left: 68%;
}
 .mouth {
	 position: absolute;
	 top: 43%;
	 left: 41%;
	 width: 7px;
	 height: 7px;
	 border-radius: 50%;
}
 .happy {
	 border: 2px solid;
	 border-color: transparent #777 #777 transparent;
	 transform: rotate(45deg);
}
 .sad {
	 top: 49%;
	 border: 2px solid;
	 border-color: #777 transparent transparent #777;
	 transform: rotate(45deg);
}
 .shadow {
	 position: absolute;
	 width: 10%;
	 height: 3%;
	 opacity: 0.5;
	 background: #777;
	 left: 45%;
	 top: 17%;
	 border-radius: 50%;
	 z-index: 1;
}
 .scale {
	 animation: scale 1s ease-in infinite;
}
 .move {
	 animation: move 3s ease-in-out infinite;
}
 .message {
	 position: absolute;
	 width: 100%;
	 text-align: center;
	 height: 40%;
	 top: 19%;
}
 .button-box {
	 position: absolute;
	 background: #fcfcfc;
	 width: 50%;
	 height: 15%;
	 border-radius: 20px;
	 top: 73%;
	 left: 25%;
	 outline: 0;
	 border: none;
	 box-shadow: 2px 2px 10px rgba(119, 119, 119, .5);
	 transition: all 0.5s ease-in-out;
}
 .button-box:hover {
	 background: #efefef;
	 transform: scale(1.05);
	 transition: all 0.3s ease-in-out;
}
 @keyframes bounce {
	 50% {
		 transform: translateY(-10px);
	}
}
 @keyframes scale {
	 50% {
		 transform: scale(0.9);
	}
}
 @keyframes roll {
	 0% {
		 transform: rotate(0deg);
		 left: 25%;
	}
	 50% {
		 left: 60%;
		 transform: rotate(168deg);
	}
	 100% {
		 transform: rotate(0deg);
		 left: 25%;
	}
}
 @keyframes move {
	 0% {
		 left: 25%;
	}
	 50% {
		 left: 60%;
	}
	 100% {
		 left: 25%;
	}
}
 footer {
	 position: absolute;
	 bottom: 0;
	 right: 0;
	 text-align: center;
	 font-size: 1em;
	 text-transform: uppercase;
	 padding: 10px;

}
 footer p {
	 color: #ef8d9c;
	 letter-spacing: 2px;
}
 footer a {
	 color: #b0db7d;
	 text-decoration: none;
}
 footer a:hover {
	 color: #ffc39e;
}

.verfifyTckt{
  overflow: scroll !important;
}
.verfifyTckt p{
 font-size: 14px ;
  justify-content: space-between;
}