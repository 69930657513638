* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

.myForm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom #fff, #ccc);
  scale: 0;
}

.myForm .box {
  position: relative;
}

.box .square {
  display: none;
  position: absolute;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  /* background-image: linear-gradient(to bottom right, rgb(199, 39, 178) , rgb(61, 63, 187)); */
  border-radius: 10px;
  animation: animateBox 10s linear infinite;
  animation-delay: calc(-1s * var(--i));
  transition: 0.3s;
}

.box .square:hover {
  animation: rotateing 3s linear infinite;
  transition: 0.3s;
  animation-iteration-count: 0.5;
}

@keyframes animateBox {

  0%,
  100% {
    transform: translateY(-40px);
  }

  50% {
    transform: translateY(40px);
  }
}

.box .square:nth-child(1) {
  top: -50px;
  right: -60px;
  width: 100px;
  height: 100px;
}

.box .square:nth-child(2) {
  top: 150px;
  left: -100px;
  width: 120px;
  height: 120px;
  z-index: 2;
}

.box .square:nth-child(3) {
  bottom: 50px;
  right: -30px;
  width: 80px;
  height: 80px;
}

.box .square:nth-child(4) {
  bottom: -80px;
  left: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.box .square:nth-child(5) {
  top: -80px;
  left: 140px;
  width: 50px;
  height: 50px;
}

@keyframes rotateing {
  0% {
    transform: rotateY(0deg);
    border-radius: 50%;
  }

  100% {
    transform: rotateY(360deg);
    border-radius: 50%;
  }
}

.container {
  position: relative;
  width: 400px;

  height: auto;
  margin: auto;
  background: rgba(48, 165, 207, 0.335);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bannerMobile {
  width: 80%;
  margin-left: 10%;
}

.add-new {
  /* position: absolute;
  top: 0; */
}

@media only screen and (max-width: 767px) {
  .container {
    width: 350px;
  }

  .bannerMobile {
    width: 90%;
    margin-left: 5%;
    position: absolute;
  }
}

.form {
  width: 100%;
  height: 100%;
  padding: 40px;
  position: relative;
}

.form h2 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgb(8, 8, 8);
  font-size: 24px;
  letter-spacing: 1.2px;
  margin-bottom: 40px;
}

.form h2::before {
  content: "";
  position: absolute;
  left: 45px;
  top: 80px;
  width: 150px;
  height: 4px;
  background: rgb(0, 0, 0);
  border-radius: 50px;
  animation: animate 10s linear infinite;
}

.form .inputBox {
  width: 100%;
  margin-top: 20px;
}

.form .inputBox input {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  color: rgb(0, 0, 0);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); */
}

.form .inputBox input:active {
  outline: none;
  border: none;
}

.form .inputBox input::placeholder {
  color: rgb(83, 76, 76);
}

.form .inputBox input[type="submit"] {
  background-image: linear-gradient(to bottom right,
      rgb(1, 128, 255),
      rgb(1, 94, 208));
  max-width: 100%;
  cursor: pointer;
  transition: 0.3s;
  color: rgb(255, 255, 255);
  font-weight: 700;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
}

.form .inputBox input[type="submit"]:hover {
  transform: scale(1.1);
}

.socialFlex {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: auto;
  margin: auto;
}

.socialLogin {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
}

.socialLogin img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

p {
  text-align: center;
  margin-top: 0px;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 1.2px;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
}

.eventDetails_weblink {
  text-transform: none !important;
}

.error {
  padding-left: 10px;
  color: rgba(255, 0, 0, 0.623);
}

.add-new-btn {
  padding: 10px 0;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #e9f4ff;
  border: solid #d7eafe 5px;
  border-radius: 6px;
}

.add-new-btn form,
.add-new-btn div {
  display: inline-block;
  justify-content: space-between;
  width: 100%;
}

.add-new-btn .manageusersmobilediv {
  margin-top: 1rem;
  align-items: center;
  width: 60%;
  gap: 12%;
}

.pop-qr-img img {
  width: 100%;
  height: 100%;
}

.pop-qr-center {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.pop-qr-img {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.toggle {
  margin-bottom: 1rem;
}

.userpopup .pop-qr-img .cam-icon {
  display: inline-block;
  transform: scale(1.5);
  margin-bottom: 0;
}

.pop-qr-center .with-img {
  flex-direction: column;
}

.add-new-btn div input,
.add-new-btn div select {
  width: 70% !important;
  height: 2rem !important;
  border-radius: 4px;
  padding: 4px 8px;
  border: solid rgb(182, 182, 250) 1px;
}

/* .eventdetailsPage {
  left: 126px !important;
} */
.ticketDetailspage {
  left: 122px !important;
}

.qrdivMobile {
  height: 400px;
  width: 400px;
  display: flex;
  margin-left: -32px;
}

/* .add-new-btn .manageusersmobilediv div {
  width: 25%;
} */

.privacypolicy {
  float: left;
  font-size: 13px;
  margin-left: 280px;
  margin-top: -8px;
  color: #1a8aff;
  text-decoration: underline;
}

@media only screen and (max-width: 957px) {
  .scannerQtyPopop {
    width: 100% !important;
  }

  .main {
    width: 100% !important;
    margin-left: -232px;
  }

  .leftnav {
    position: fixed;
    top: 1 !important;
  }

  .gridbox {
    width: 90% !important;
  }

  .logo img {
    /* margin-top: 27px !important; */
    mix-blend-mode: hard-light !important;
  }

  .privacypolicy {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 767px) {

  /* .Responsive-Container{
    margin-left: 12px !important;
    width: 430px !important;
    height:100px !important;
    overflow: hidden ;
  } */
  .printticketafterverify {
    position: absolute;
    background: rgb(37 97 207);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    top: 5.5% !important;
    right: 7%;
    border-radius: 10px;
    z-index: 1111;
    padding: 10px 20px;
    box-shadow: 0 0 20px 0 rgb(165 158 158 / 90%);
    border: 2px solid transparent;
  }

  .main {
    width: 370px;
    margin-left: -232px;
  }

  /* .eventdetailsPage {
    left: 207px !important;
    width: 116% !important;
  } */
  .ticketDetailspage {
    width: 131% !important;
  }

  .asklogout {
    margin-left: -208px !important;
  }

  .detailbox {
    left: 7% !important;
  }

  .userpopup {
    left: 3% !important;
    width: 348px !important;
  }

  .qrdivMobile {
    height: 356px !important;
  }

  .center {
    margin-left: 75px !important;
  }

  .logo img {
    height: 70px !important;
    margin-top: -1px !important;
  }

  .topbuttons {
    font-size: 10px !important;
  }

  .afterclicktopbuttons {
    font-size: 10px !important;
    color: #111 !important;
  }

  .manageusersmobilediv {
    position: relative !important;
  }

  /* .flexedbox {
    margin-left: 40px;
    margin-top: 374px !important;
  } */
  .qrpage-poup {
    position: absolute !important;
    top: 10% !important;
    left: 2% !important;
    width: 337px !important;
  }

  .privacypolicy {
    margin-left: 26px;
  }

  .space-child {
    flex-basis: calc(31% - 60px);
    /* Each item takes up 33.33% of the available space with some spacing */
  }
}

.printticketafterverify {
  position: absolute;
  background: rgb(37 97 207);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  top: 7%;
  right: 7%;
  z-index: 1111;
  padding: 10px 20px;
  box-shadow: 0 0 20px 0 rgb(165 158 158 / 90%);
  border: 2px solid transparent;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 107%);
  z-index: 1;
  overflow: scroll;
}

.qrpage-poup {
  position: absolute;
  top: 10%;
  left: 37%;
  height: auto;
  padding-bottom: 15px;
  width: 350px;
  background: #fff;
  z-index: 999;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

.center {
  align-items: center;
  text-align: center;
  margin: auto;
  margin-left: 90px;
  height: 100px;
  width: 100px;
}

.fb {
  height: 50px;
  width: 100px;
}

.redirectingloginLink {
  font-size: 14px;
  text-transform: capitalize;
}

.loginlink {
  color: rgba(0, 98, 255, 0.904);
}

.heading {
  color: rgba(0, 98, 255, 0.808);
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
}

.block {
  display: block;
  transition: 0.5s;
}

.mb {
  transition: 0.5s;
}

/* custom animation classes */

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.selectinp,
select {
  border-color: transparent;
  box-shadow: 0 2px 1px transparent inset, 0 0 8px transparent;
  outline: 0 none;
  border-bottom: 2px solid rgb(55, 108, 255);
  background: transparent;
}

input[type="date"] {
  width: 222px;
  border-color: transparent;
  background: transparent;
  /* box-shadow: 0 2px 1px #fff inset, 0 0 8px rgba(255, 255, 255, 0.6); */
  outline: 0 none;
  border-bottom: 1px solid rgb(55, 108, 255);
}

/* 
.animatingTop {
  -webkit-animation: animatingTop 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
          animation: animatingTop 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



@-webkit-keyframes animatingTop {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes animatingTop {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}



.animatingBottom {
  -webkit-animation: animatingBottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
          animation: animatingBottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



@-webkit-keyframes animatingBottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes animatingBottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.animatingRight {
  -webkit-animation: animatingRight 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
          animation: animatingRight 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes animatingRight {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateX(500px);
            transform: translateZ(-700px) translateX(500px);
    opacity: 0;
  
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@keyframes animatingRight{
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateX(500px);
            transform: translateZ(-700px) translateX(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}


.animatingLeft {
  -webkit-animation: animatingLeft 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
          animation: animatingLeft 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes animatingLeft {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateX(-500px);
            transform: translateZ(-700px) translateX(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
    z-index: 9999 !important;
  }
}

@keyframes animatingLeft {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateX(-500px);
            transform: translateZ(-700px) translateX(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

 */

.notfound {
  display: flex;
  justify-content: center;
  height: 90vh;
  width: 100%;
}

.notfound img {
  height: 100%;
  width: 100%;
}

.backtohome {
  position: absolute;
  top: 24%;
  left: 2%;
  height: 60px;
  border-radius: 50%;
  width: 110px;
  border: none;
  background: transparent;
}

.backtohome::before {
  content: "BACK TO HOME";
  position: absolute;
  top: 5px;
  left: 80px;
  color: rgb(255, 255, 255);
  font-weight: 900;
  font-size: 30px;
  z-index: 1;
  width: 300px;
}

.backtohome ion-icon {
  color: #ffffff;
  font-size: 40px;
}

.reportloader {
  height: 50vh;

  display: flex;
  justify-content: start;
}

.qrcodescanner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
}

.copytoclipboard {
  position: absolute;
  left: 60%;
  bottom: 150px;
}

.copy ion-icon {
  color: #fff;
}

.goobutton {
  color: #fff;
  list-style: none;
  padding: 5px 50px;
  margin-top: 20px;
  background: rgba(0, 98, 255, 0.808);
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
}

.goobutton:hover {
  border: 1px solid rgba(0, 98, 255, 0.808);
  color: #fff;
  background: transparent;
}

.b-radius {
  border-radius: 4px;
  overflow: hidden;
}

/* apply CSS to the select tag of 
         .dropdown-container div*/

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

select::selection {
  display: none !important;
}

/* for IE10 */
select::-ms-expand {
  display: none !important;
}

.bannersimg {
  height: 350px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1),
    0 0 1000px rgba(255, 255, 255, 0.95);
}

.arrow {
  position: absolute;
  color: #000000;
  font-size: 2.5em;
  font-weight: bold;

  cursor: pointer;
}

.nextArrow {
  top: 43%;
  right: 42px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
}

.prevArrow {
  top: 43%;
  left: 42px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
}

.MuiDataGrid-columnHeaders {

  font-weight: 900 !important;

  color: white;
  /* justify-content: center; */
  -webkit-animation: animatingBottom 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: animatingBottom 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.loaderDiv {
  left: 20%;
  top: 15%;
  bottom: 0;

  /* background-color: #2c2c2cd9 !important; */
  position: absolute !important;
  text-align: center !important;
  z-index: 9999999;
}

.loaderDiv img {
  height: 200px;
  width: 300px;
}

.active {
  color: rgb(0, 0, 0);
  font-weight: 900;
}

.active:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 147px;
  left: 41px;
  margin-top: 37px;
  background: rgb(255, 255, 255);
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
  width: 89% !important;
}

.MuiDataGrid-columnHeaderDraggableContainer {
  width: 89% !important;
}

.MuiDataGrid-menuList {
  display: grid !important;
  padding: 10px !important;
  box-shadow: 0 0 10px 0 rgba(131, 129, 129, 0.8) !important;
}

a {
  color: #fff;
}

.popoupalign p {
  display: flex;
  width: 408px;
}

.popoupalign .span {
  color: #0171ea;
  margin-left: 12px;
  text-align: start;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  height: 38px !important;
}

.boothcounter input {
  width: 100px;
  height: 42px;
  text-align: center;
  border: 2px solid #111 !important;
}

.boothcounter button {
  height: 40px;
  width: 40px;
  background: #0174ef;

  color: #fff;
}

/* -------------------- */

.banner {
  width: 96%;
  margin-left: 2%;
  background: linear-gradient(#fe63475e, #0167dd5e);
  height: 5em;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  border-radius: 0.3em;
}

.banner_data {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: auto;
  text-align: center;
}

.banner_data span {
  color: rgb(255, 104, 17);
  font-size: 24px;
}

.banner:hover {
  box-shadow: 0 5px 15px 10px rgba(0, 0, 0, 0.55);
}

.shopping-image {
  height: auto;
  width: 98%;
}

.shopping-image>img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 0 2em 2em 0Array/0Array 3em 3em 0;
}

.text {
  height: 2em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px;
}

.text>* {
  font-size: 12px;
  text-align: center;
}

a {
  color: #fff;
}

.BTheading {
  font-weight: 800;
  font-size: 22px;
}

.flexedbox .space {
  width: 58%;
}

.flexedbox .spaced {
  width: 94%;
  margin-left: 3%;
  justify-content: space-between;
}

.Buydetails {
  height: 80vh;
  width: 24%;

  position: absolute;
  top: 33px;
  left: 57%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  border-color: transparent;
  background: transparent;
  /* box-shadow: 0 2px 1px #fff inset, 0 0 8px rgba(255, 255, 255, 0.6); */
  outline: 0 none;
  border-bottom: 1px solid rgb(55, 108, 255);
}

.css-b62m3t-container {
  width: 200px;
  border-color: transparent;
  background: transparent;
  /* box-shadow: 0 2px 1px #fff inset, 0 0 8px rgba(255, 255, 255, 0.6); */
  outline: 0 none;
  border-bottom: 1px solid rgb(55, 108, 255);
}

.popupdata input[type="file"] {
  border-color: transparent;
  background: transparent;
  /* box-shadow: 0 2px 1px #fff inset, 0 0 8px rgba(255, 255, 255, 0.6); */
  outline: 0 none;
  border-bottom: 1px solid rgb(55, 108, 255);
}

.popupdata video {
  height: 200px !important;
}

.printPdfpaper {
  height: 400px;
  width: 30%;
  margin: auto;
  margin-top: 120px;
  border: 1px solid #333;
}

.printPdfpaper_flexedImg {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
  margin-bottom: 22px;
}

.ticket_ticket_id {
  font-size: 15px !important;
}

.printPdfpaper_bannerImg {
  height: 120px;
  width: 100%;
  background: #fff;

  margin-bottom: 20px;
}

.ticketclient_name {
  font-size: 18px !important;
}

.ticketcompany_name {
  font-size: 14px !important;
}

.printPdfpaper_vip {
  height: 40px;
  width: 100%;
  background: #fff;
}

.flex-button {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

#react-select-3-listbox {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
}

.sharemailDiv {
  display: flex;
  justify-content: space-between;
}

.sharemailDiv button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(196, 191, 191, 0.1);
  margin-left: 20px;
  background: #0b7dff;
  border: none;
  color: #fff;
}

.buytitcketpagedegign input:focus {
  border-bottom: 2px solid tomato;
}

.buytitcketpagedegign select,
input {
  width: 100% !important;
}

.buytitcketpagedegign select:focus {
  border-bottom: 2px solid tomato;
}

.buytitcketpagedegign .boothinput {
  width: 100px !important;
}

#isPhoto {
  width: 5% !important;
}

.dashboardMain .table>div>div>div:first-child {
  position: absolute;
  bottom: 1%;
  /* background: #1a50f8; */
  right: 34%;
  border-radius: 8px;
  z-index: -1000;
}

.eventdetailTable>div>div:first-child {
  position: absolute;
  bottom: 0;
  /* background: #1a50f8; */
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
}

.dashtbl>div>div:first-child {
  /* position: absolute;
  /* bottom: 60%; */
  /* background: #1a50f8; */
  /* right: 100%;
  border-radius: 8px;
  z-index: 1; */
  position: absolute;
  bottom: 0%;
  /* background: #1a50f8; */
  right: 31%;
  border-radius: 8px;
  z-index: 1;
}

.allordrtbl>div>div:first-child {
  position: absolute;
  bottom: 1%;
  /* background: #1a50f8; */
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
}

/* .onspottbl > div > div:first-child {
  position: absolute;
  bottom: 1%;
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
} */

.reporttbl>div>div:first-child {
  position: absolute;
  bottom: 1%;
  /* background: #1a50f8; */
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
}

.categtbl>div>div:first-child {
  position: absolute;
  bottom: 1%;
  /* background: #1a50f8; */
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
}

.supporttbl>div>div:first-child {
  position: absolute;
  bottom: 1%;
  /* background: #1a50f8; */
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
}

.supporttbl {
  margin-top: 20px;
}

.userpopup button {
  border-radius: 5px;
  background-color: red;
  padding: 6px 18px;
  font-size: 14px;
}

.pop-qr-img button {
  background: #016fe8;
  border: 2px solid #016fe8;
  color: #fff;
  height: 2rem;
  padding: 2px 4px;
}

/* .ordertbl > div > div:first-child {
  position: absolute;
  bottom: 1%;
  right: 20%;
  border-radius: 8px;
  z-index: -1000;
} */

.ReactModalPortal img {
  height: 400px;
  object-fit: contain;
}

.table>div>div>.MuiDataGrid-columnHeaders {
  display: block !important;
}

.table>div>div>.MuiDataGrid-footerContainer {
  display: block !important;
}

.MuiDataGrid-footerContainer {
  min-height: 52px !important;
  margin-bottom: -12px !important;
}

.table> :not(caption)>*>* {
  padding: 0 !important;
}

.table> :not(:first-child) {
  border-top: none !important;
}

.MuiDataGrid-columnHeaders {
  background: rgb(45 96 246) !important;
  text-transform: uppercase !important;
}

.dashtbl {
  width: 94%;
  margin-left: 3%;
  height: 70%;
}

.item-master-tbl {
  margin-bottom: 0;
  margin-top: 12px;
  height: 90%;
}

.rankingtbl {
  height: 76%;
}

.onspottbl {
  height: 45%;
}

@media only screen and (min-width: 1390px) {
  .ordertbl {
    height: 470px;
  }

  .dashtbl {
    /* height: 450px; */
    height: 80%;
    /* width: 96%; */
    margin-left: 2%;
  }

  .item-master-tbl {
    height: 88%;
  }

  .rnk-main {
    height: 86vh;
  }

  .rankingtbl {
    height: 78%;
  }

  .allordrtbl {
    height: 450px;
  }

  .categtbl {
    height: 430px;
  }

  .reporttbl {
    height: 360px;
  }

  .onspottbl {
    height: 45%;
  }
}

@media only screen and (max-width: 980px) {
  .Buydetails {
    height: 35vh;
    width: 100%;

    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .Buydetails {
    height: 25vh;
    width: 100%;

    position: absolute;
    /* bottom: 0 !important; */
    left: 13px !important;
    top: 20% !important;
  }

  .pagenames {
    /* margin-left: 30px !important; */
    -webkit-text-stroke: 1px #1c87c9;
    color: transparent !important;
    text-stroke: 1px transparent;
  }

  .space-child:nth-child(3) {
    /* justify-content: center; */
    margin-left: 45%;
  }

  .rankingtbl {
    margin-top: 0px;
  }

  .bannersimg {
    height: 200px;
  }

  .nextArrow {
    top: 35%;
    right: 20px;
  }

  .prevArrow {
    top: 35%;
    left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .space-child:nth-child(3) {
    /* justify-content: center; */
    margin-left: 40%;
  }
}

.pagenames {
  /* margin-left: 23px; */
  /* -webkit-text-stroke: 1px #1c87c9; */
  color: #111;
  text-stroke: 1px transparent;
}

.pagenames span {
  color: #0b7dff;
  text-transform: uppercase;
}

.orgrank {
  /* margin-left: -200px ; */
  text-transform: uppercase;
  font-weight: 900;
  font-size: 28px;
  padding: 13px;
  margin-bottom: -30px;
  width: 100%;
}

button:disabled {
  cursor: not-allowed !important;
}

.Mui-disabled {
  cursor: not-allowed !important;
  color: grey !important;
}

.scllCls {
  height: 80% !important;
  overflow: scroll;
  width: 450px !important;
}

.scannerQtyPopop {
  width: 30%;
  background: #fff;
  padding: 20px;
  border-radius: 13px;
}

.deleltebannerbtn {
  width: 200px !important;
  border: 2px solid red;
  border-radius: 10px;
  position: relative;
}

.deletebannerbtn::before {
  content: "Delete";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 200px;
  background: green;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.MuiTablePagination-toolbar {
  margin-top: -13px;
}

.croperPopup {
  position: absolute;
  /* top: 9%; */
  left: 30px;
  width: 80vw !important;
  background: #fff;
  z-index: 999;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

.croperPopup>div>div {
  height: 90vh !important;
  width: 80vw !important;
}

.bannerimgMsg {
  margin-left: -200px;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 6px;
  cursor: pointer;
}