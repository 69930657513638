.imageupload {
}
.fullpage {
  height: 100vh;
  width: 100%;
  background: #111;
  /* background: red; */
}
.eventbanner {
  height: 400px;
  width: 80%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}

.eventname {
  font-weight: 900;
  font-size: 44px;
  letter-spacing: 3.2px;
  color: rgb(13, 118, 255);
  text-transform: capitalize;
  /* margin-left: 130px; */
  text-align: center;
}
.eventbanner img {
  height: 100%;
  width: 100%;
}
.eventdetails {
  display: flex;
  justify-content: space-between;
  height: auto;
  overflow-y: scroll;
  width: 80%;
  padding-top: 20px;
  margin: auto;
  /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.6); */
}
.eventdetails {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.eventdetails::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.eventdetails span {
  color: #111;
  text-align: start;
  float: left;
  font-weight: 900;
  width: 200px;
}
.carouselRegistration {
  box-shadow: 0 0 10px 0 rgba(214, 214, 214, 0.6);
}
.carouselRegistration > div {
  width: 98% !important;
}
.eventdetails div {
  width: 50%;
  padding: 20px;
  /* background: red; */
}
.eventdetails p {
  color: rgb(13, 118, 255);
  text-align: start;
  display: flex;
}
.flex {
  /* width: 80%; */
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.createticketform {
  /* margin-top: 100px; */
  padding: 30px;
  width: 80%;
  margin: auto;
  height: 400px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}
.staticalert {
  width: 70%;
  position: fixed;
  top: 40px;
  left: 15%;
}
.slider-container {
  width: 100% !important;
}
.slider-container > div {
  border-radius: 4px;
  overflow: hidden;
  width: 90% !important;
  margin: 0 auto;
}
.slider-container > div > div {
  width: 90% !important;
  margin: 0 auto !important;
}

.rsis-container {
  height: 100% !important;
}
.rsis-image {
  height: 100% !important;
}
.eventdetailTable {
  height: -webkit-fill-available;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 3rem;
  height: 100%;
  max-height: 400px;
}

.eventdetailTable p {
  font-size: larger;
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .eventbanner {
    height: 203px;
    width: 80%;
    margin: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  }
  .staticalert {
    width: 70%;
    position: fixed;
    top: 40px;
    left: 1%;
  }
  .slider-container {
    height: 200px;
  }
  .slider-container > div {
    width: 90% !important;
    height: 200px !important;
    margin: 0 auto;
  }
  .slider-container > div > div {
    width: 90% !important;
    height: 200px !important;
    margin: 0 auto !important;
  }
}
.flexed {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.thisevent_tickets {
  height: 60px;
  width: 80%;
  margin: auto;
  margin-top: 10px !important;
  background: rgb(13, 118, 255);
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}
.thisevent_booth {
  height: 60px;
  width: 80%;
  margin: auto;
  margin-top: 10px !important;
  background: aqua;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}
.thisevent_addon {
  height: 60px;
  width: 80%;
  margin: auto;
  margin-top: 10px !important;
  background: rgb(4, 152, 221);
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}
.thisevent_booth .para p {
  color: rgb(8, 8, 8);
}
.thisevent_tickets_icon {
  position: relative;
  top: -10px;
  left: -10px;
  height: 30px;
  width: 30px;
  background: rgb(255, 189, 9);
  border-radius: 50%;
}

.thisevent_tickets_icon ion-icon {
  padding: 7px;
  color: rgb(0, 0, 0);
}
.display_flex {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding: 0;
  margin-top: -30px;
}
.display_flex p {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .display_flex {
    display: block;
  }

  .thisevent_booth {
    height: 250px;
  }
  .thisevent_tickets {
    height: 250px;
  }
  .createticketform {
    /* margin-top: 100px; */
    /* padding: 30px; */
    width: 80%;
    margin: auto;
    height: 1030px;
    box-shadow: transparent !important;
  }
}

.eventsearchflex {
  display: flex;
  justify-content: space-between;
  width: 70%;
  /* background: red; */
  height: 50px;
  position: absolute;
  top: 1%;
  right: 3%;
  margin-bottom: 30px;
  height: 100px;
  /* z-index: 111; */
}
.searchbtn ion-icon {
  font-size: 20px;
  margin-top: 10px;
  margin-left: -18px;
  color: blue;
  transform: scale(1.9);
  font-weight: bold;
}

/* //////////////////////////////////////////////// */

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
  width: 183px;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 11111 !important;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 20px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 11111 !important;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}
.options {
  z-index: 111;
}

/*  */

.slider-container {
  width: 100%;
}

@media (max-width: 768px) {
  /* .slider-container {
    width: 100%;
    max-width: 400px;
  } */
  /* .slider-container > div {
    width: 390px !important;
  } */
  .eventsearchflex {
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* background: red; */
    height: 50px;
    position: static;
    top: 0%;
    right: 7%;
    margin-bottom: 0px;
    height: 100px;
    z-index: 111;
  }
}
.slider-container > div {
  margin: 0 auto !important;
}
@media (min-width: 769px) and (max-width: 1200px) {
  /* .slider-container {
    max-width: 800px;
  } */

  .slider-container > div {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media (min-width: 1201px) {
  .slider-container {
    /* width: 100%; */
    /* max-width: 1220px;  */
  }
}
