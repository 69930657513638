.aletpopup{
    position: absolute;
    top: 2%;
    left: 20%;
    box-shadow: 0 0 30px 0 rgba(24, 120, 209, 0.767);
    z-index: 1;
    height: 65px;
    width: 60%;
    border-radius: 8px;
  
    background-image: linear-gradient(to bottom right,rgba(255, 255, 255, 0.905),rgb(219, 214, 214));
    text-align: start;
    padding: 20px;
    padding-left: 30px;
  }
  @media only screen and (max-width: 767px){
    .aletpopup{
      left: 17%;
      font-size: 12px;
      width: 80%;

    }
    .qrticketmobile{
      /* left: 2% !important; */
    }
   
}

@media only screen and (max-width: 400px){
  .aletpopup{
    left: 17%;
    font-size: 12px;
    width: 80%;
    height: auto !important;
  }

 
}
  span ion-icon{
    float: right;
    cursor: pointer;
  }
  .none{
    display: none;
  }

  .qrticketmobile{
    left: 17% !important;
  }