.space {
  width: 100%;
  padding: 5px 40px;
  /* position: absolute; */
    /* z-index: 111; */
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}

label {
  color: rgba(7, 90, 243, 0.733);
  font-weight: 700;
  text-transform: uppercase;
}
input[type="text"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(124, 165, 241);
  /* box-shadow: 0 0 5px 0 rgba(62, 57, 57, 0.9); */
  border-radius: 1px;
}

@media only screen and (max-width: 980px) {
  /* .space {
    width: 100% !important;
    padding: 5px 40px;
    display: flex;
  } */
}

@media only screen and (max-width: 767px) {
  .space input {
    display: block;

    float: left;
  }

  .small {
    display: block;
  }

  .space {
    padding: 5px 20px;
    flex-wrap: wrap;
    position: static;
  }

}
