@media only screen and (max-width: 980px) {
  .main {
    width: 100%;
    margin-left: -270px;
  }
  .d-flex {
    display: block !important;
    width: 400px !important;
  }
}

/* .add-new-btn {
  margin-top: 1rem;
  align-items: center;
  width: 60%;
  gap: 12%;
} */

.add-new-btn form,
.add-new-btn div {
  display: inline-block;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
.add-new-btn .category-div {
  align-items: center;
  width: 100%;
  gap: 33%;
}

.add-new-btn .category-div div input {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .main {
    width: 370px;
    margin-left: -270px;
  }
  .d-flex {
    display: block !important;
    width: 400px !important;
  }
}
