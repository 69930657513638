nav {
  /* position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000; */
  height: 70px;
  width: 100%;
  background-image: linear-gradient(to top right, #306af7, #4095fc);
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.9);
  align-items: center;
}
.logo {
  position: absolute;
  margin-left: 1rem;
  /* top: -7%; */
  /* padding: 45px; */
}
.logo img {
  height: 70px;
  mix-blend-mode: color-blur;
  padding: 8px;
}
.searchBox {
  position: relative;
  margin-left: 140px;
  margin-top: 20px;
  height: 10px;
  width: 100%;
}
.searchBox input {
  height: 40px;
  width: 600px;
  outline: none;
  background-color: transparent;
  /* box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.9); */
  border: 2px solid transparent;
  border-radius: 30px;
  padding-left: 50px;
  color: whitesmoke;
}
@media only screen and (max-width: 767px) {
  .searchBox input {
    width: 150px;
    margin-left: -50px;
    margin-top: -5px;
  }
  /* .logo {
    margin-left: 0;
  } */
  /* .logo img {
    padding: 10px;
  } */
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-top: 15px;
  margin-right: 70px;
}
.navbutton {
  margin-left: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.9); */
}
.navbutton ion-icon {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}
.username {
  width: 100%;
  margin-right: 10px;
}
.scaner ion-icon {
  color: #fff;
  padding-top: 10px;
  font-size: 20px;
  cursor: pointer;
}
