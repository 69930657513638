.reportfilterselectopt{
    background: cornflowerblue;
    padding: 4px;
    border: 2px solid cornflowerblue;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgb(126 126 126 / 90%);
    color: #fff;
}
.reportfilterselectopt option{
    background: cornflowerblue;
    padding: 4px;
    border: 2px solid cornflowerblue;
    border-radius: 4px;
    box-shadow: inset 0 0 50px 0 rgb(126 126 126 / 90%)
}