.selectinp {
  width: 215px;

  border-radius: 5px;
  /* box-shadow: 0 0 10px 0 rgba(224, 221, 221, 0.7); */
}


.userpopup {
    border: solid silver 1px;
  position: absolute;
  top: 10%;
  left: 20%;
  height: auto;
  padding: 20px 30px;
  width: 400px;
  background: #fff;
  background-image: linear-gradient(#fff #949292 ) ;
  z-index: 999;
  text-align: center;

  -moz-box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}
.popupdata span {
  color: grey;
  font-weight: 700;
  letter-spacing: 1.2px;
}
.popupdata p {
  font-size: 16px;
  /* line-height: 10px; */
  text-align: start;
  color: rgb(6, 61, 243);
  margin-bottom: 26px;
  text-align: center;
}


.toggle button {
  height: 40px;
  width: 40px;
  margin-left: 12px;
  border-radius: 50%;
  margin-top: -16px;
  color: rgb(43, 99, 255);
  box-shadow: 0 0 40px 0 rgba(82, 81, 81, 0.9);
  border: 2px solid rgb(43, 99, 255);
  background: rgb(43, 99, 255);
}
.toggle ion-icon {
  font-size: 25px;
  color: #fff;
  background: rgb(43, 99, 255);
  border-radius: 50%;
  font-weight: 900;
}
#plusadduser::after {
  content: "Add New";
  text-transform: capitalize;
  font-weight: 700;
  position: absolute;
  top: 10;
  width: 100px;
  left: 40px;
  color: rgb(43, 99, 255);
}

.imageupload {
  /* width: 220px; */
  display: block;
}

.imageview {
  /* display: block; */
  height: 100px;
  width: 300px;
}
.imageview img {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 30px 0 rgba(66, 66, 66, 0.9);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 999;
}

.fullImage {
  transform: scale(2.9);
}

.showbanner {
  position: absolute;
  top: 0;
  left: 0;
  height: 70vh;
  z-index: 1;
  width: 70%;
  display: none;
  background: red;
}
.close {
  position: absolute;
  right: -10%;
  top: 0;
  background: red;
  color: #333;
}
